
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import SearchBar from '@/components/search-bars/SearchBar.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import UniqueCourseDrawer from '@/layout/header/partials/report/UniqueCourseDrawer.vue';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import axios from 'axios';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { ElNotification } from 'element-plus';
import Multiselect from '@vueform/multiselect';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'trainer-list',
  components: {
    Form,
    Datatable,
    SearchBar,
    UniqueCourseDrawer,
    Swal,
    Multiselect,
  },
  data() {
    return {
      api_url: '',
      load: false,
      loading: false,
      tranche: [] as any,
      btnCheck: false,
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '100px',
        },
        {
          name: 'SL',
          key: 'sl',
        },
        {
          name: 'Unique Course',
          key: 'unique_course',
          sortable: true,
        },
        {
          name: 'Code',
          key: 'code',
          sortable: true,
        },
        {
          name: 'Name of industry Association / Govt. Org',
          key: 'association',
          sortable: true,
          width: '300px',
        },
        {
          name: 'Course Found',
          key: 'course',
          sortable: true,
        },
      ],
      selectedTranche: [] as any,
      optionsTranche: [] as any,
      showCourseNotice: false,
      componentKey: 0,
      actionActive: false,
      uniqueCourseList: [] as any,
      selectAll: {
        id: 'all',
        label: 'All',
      } as any,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    await this.getTranche();
  },
  methods: {
    async printPdf() {
      let formData = new FormData();
      formData.set('tranche', this.selectedTranche);
      let data = `${this.VUE_APP_API_URL}/api/course/unique_print`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();


      formData.set('tranche', this.selectedTranche);

      await ApiService.post('course/unique_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();


    formData.set('tranche', this.selectedTranche);


      axios
        .post(
          `${this.VUE_APP_API_URL}/api/course/unique_excel`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'course_training_statistics.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async courseInfoUnique() {
      this.load = true;
      await ApiService.get(
        'course/uniqueCourse?tranche=' + this.selectedTranche
      )
        .then((response) => {
          this.uniqueCourseList = response.data.data;
          this.showCourseNotice = true;
          this.componentKey += 1;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async btnCheckStatus() {
      this.btnCheck = true;
    },
    view(data) {
      // console.log(data);
      this.emitter.emit('course_unique_details', data);
    },

    async getTranche() {
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          console.log(response.data.data);
          this.tranche = response.data.data;
         this.tranche.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    // async actioncheck() {
    //   let menu = JSON.parse(localStorage.getItem("menu") || "{}");
    //   if (menu) {
    //     for (let i = 0; i < menu.length; i++) {
    //       //console.log(menu[i].action);
    //       if (menu[i].heading == "Trainer") {
    //         //console.log(menu[i].action);
    //         let actions = menu[i].action;
    //         //console.log(actions);
    //         for (let j = 0; j < actions.length; j++) {
    //           if (actions[j].action_name === "Edit") {
    //             // console.log(actions[j].action_name);
    //             this.actionEdit = true;
    //           }
    //           if (actions[j].action_name === "Delete") {
    //             // console.log(actions[j].action_name);
    //             this.actionDelete = true;
    //           }
    //           if (actions[j].action_name === "Approve") {
    //             // console.log(actions[j].action_name);
    //             this.actionActive = true;
    //           }
    //         }
    //       }
    //     }
    //   }
    // },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
